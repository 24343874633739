<template>
    <b-row>
        <!-- <b-modal
            id="edit_modal"
            ref="edit_modal"
            title="Edit Confirmation"
        >
            <p class="my-4">
                Please finish editing your details.
            </p>
        </b-modal> -->
        <b-modal
            id="confirm_pending_task_modal"
            ref="confirm_pending_task_modal"
            hide-footer
        >
            <template #modal-title>
                Confirm Task
            </template>
            <div class="d-block text-center">
                <h3>Please confirm to complete this task.</h3>
            </div>
            <b-button
                class="mt-3"
                block
                @click="triggerNoConfirmTask"
                >
                No
            </b-button>
            <b-button
                class="mt-3 btn-danger"
                block
                @click="changeTaskStatus()"
                >
                Yes
            </b-button>
        </b-modal>
        <b-modal
            id="pending_tasks_modal"
            size="lg"
            ref="pending_tasks_modal"
            title="Pending Tasks"
        >
            <div class="all-tasks-modal">
            <div v-for="task in pendingTasks" :key="task.task_id" class="task-modal">
                <p class="pt-1">
                    <b>Name</b>: &nbsp; {{ task.task_name }}
                </p>
                <p>
                    <b>Description</b>: &nbsp; {{ task.task_description }}
                </p>
                <p>
                    <b>Registration number</b>: &nbsp; {{ task.registration_number }}
                </p>
                <div class="d-flex">
                    <b-form-checkbox
                        :checked="task.task_id === taskId"
                        @change="confirmTask(task.task_id)"
                    />
                    <p class="mr-1">Mark as completed</p>
                </div>
            </div>
            </div>
        </b-modal>
        <b-col
            col
            lg="12"
            md="12"
            sm="12"
        >
            <div class="d-flex justify-content-end align-items-center">
                <a v-if="pendingTasks.length>0" class="link-color"><p class="mr-1 pt-1" @click="triggerPendingTasks">Pending Tasks</p></a>
                <p class="mr-1 pt-1">
                    Current Status: &nbsp; <b class="upcase">{{ filterStatus }}</b>
                </p>
                <b-button
                    v-if="allTypePermissionTest()"
                    variant="outline-warning"
                    @click="submitStatus"
                >
                 {{ btnCode }}
                </b-button>
            </div>

        </b-col>
        <b-col
            col
            lg="12"
            md="12"
            sm="12"
        >
            <b-tabs
        pills
        v-model="tabIndex"
    >
        <b-tab
            title="Vehicle"
            lazy
            :disabled="disableTabs && !(tabIndex === 0)"
        >
            <vehicle
            job-id="5"
            :role-id-for-block="roleIdForBlock"
            :check-completed = "checkCompleted"
            @enable-tabs="changeTabStatus"
            @tab-edit-status="tabEdit"
            />
        </b-tab>
        <b-tab
        title="Detail"
        lazy
        :disabled="disableTabs"
        >
            <Job
            :role-id-for-block="roleIdForBlock"
            :check-completed = "checkCompleted"
            />
        </b-tab>
        <b-tab
            title="Overview"
            lazy
            :disabled="disableTabs && !(tabIndex === 2)"
        >
            <overview
            job-id="5"
            :profile-id="profileId"
            :role-id-for-block="roleIdForBlock"
            :role-id-priority = "roleIdPriority"
            :check-completed = "checkCompleted"
            @enable-tabs="changeTabStatus"
            @tab-edit-status="tabEdit"
            />
        </b-tab>
        <b-tab
            title="Billing"
            lazy
            v-if="this.code!=='VIR'"
            :disabled="disableTabs"
        >
            <Billing
            :role-id-for-block="roleIdForBlock"
            :profile-id="profileId"
            :role-id-priority = "roleIdPriority"
            :check-completed = "checkCompleted"
            :status="status"
            />
        </b-tab>
    </b-tabs>
    </b-col>
    <b-button
        class="float"
        size="sm"
        variant="outline-primary"
        @click="getScrollTop"
    >
        <feather-icon icon="ChevronUpIcon" />
    </b-button>
    </b-row>
</template>
<script>
import axios from 'axios'
import {
 BTabs, BTab, BRow, BCol, BButton, BFormCheckbox,
} from "bootstrap-vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Overview from "./components/overview.vue";
import Vehicle from "./components/vehichle.vue";
import Job from "./components/job.vue";
import Billing from "./components/billing.vue";
export default {
    components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BButton,
    Overview,
    Vehicle,
    Job,
    Billing,
    BFormCheckbox,
    },
    data() {
        return {
            id: 0,
            roleIdForBlock: true,
            roleIdPriority: true,
            profileId: 0,
            status: '',
            code: '',
            btnCode: '',
            filterStatus: '',
            disableTabs: false,
            longitude: '',
            latitude: '',
            latlngLocation: '',
            editStatus: false,
            tabIndex: 0,
            hasNote: 0,
            userId: 0,
            checkCompleted: true,
            statusCheck: '',
            pendingTasks: [],
            taskId: 0,
        };
    },
    created() {
        this.id = this.$route.params.id;
        this.roleIdForBlock = this.$store.state.auth.user.roles[0].id !== 6
        this.roleIdPriority = this.$store.state.auth.user.roles[0].id < 4
        this.userId = this.$store.state.auth.user.id
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.$helper.showLoading();
            this.$axios.get(`/jobs/get_job_overview/${this.id}`)
                .then(res => {
                    this.$helper.hideLoading();
                    if (res.data) {
                        this.status = res.data.status;
                        this.checkCompleted = this.status !== 'complete'
                        this.code = res.data.job_type_code;
                        this.profileId = this.$store.state.auth.user.roles[0].id;
                        this.filterStatus = this.status.replaceAll('_', ' ')
                        this.btnCode = this.buttonVal(this.code.toLowerCase().concat(this.status));
                        this.statusCheck = this.code.toLowerCase().concat(this.status)
                        this.disableTabs = res.data.disable_tabs;
                        this.latitude = res.data.latitude;
                        this.longitude = res.data.longitude;
                        this.pendingTasks = res.data.pending_tasks ? res.data.pending_tasks : []
                        this.getAddress(this.latitude, this.longitude);
                        console.log('main page', res.data)
                        this.hasNote = res.data.has_note
                        if (this.hasNote) {
                                this.noteAlert = false
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'A note has been entered',
                                        icon: 'AlertCircleIcon',
                                        variant: 'success',
                                    },
                                });
                            }
                    }
                })
                .catch(error => {
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                    console.error(error);
                });
        },
        submitStatus() {
            const statusCode = this.refStatus(this.code.toLowerCase().concat(this.status));
            // const checkID = this.status === "draft" ? 4 : 2;
            // console.log('test', this.profileId, checkID)
            // if (this.profileId <= checkID && this.status !== 'approved') {
            if (this.allTypePermissionTest() && this.status !== 'approved') {
                this.$axios.post(`/jobs/change_job_status/${this.id}`, {
                    id: this.id,
                    status: statusCode,
                    user_id: this.userId,
                })
                    .then(res => {
                        console.log(res.data)
                        if (res.data.status) {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Submitted successfully',
                                    icon: 'CheckIcon',
                                    variant: 'success',
                                },
                            });
                        }
                        if (this.status === 'draft') {
                            this.$router.push('/account/jobs').catch(() => {});
                        }
                        this.fetchData();
                    })
                    .catch(err => {
                        this.$toast({
                            component: ToastificationContent,
                            props: 'Oops! Somthing went wrong',
                            icon: 'AllertCircleIcon',
                            variant: 'success',
                        });
                        console.error(err)
                    });
            } else if (this.allTypePermissionTest() && this.status === 'approved') {
                this.$helper.showLoading();
                this.status = 'complete'
                this.$axios.post(`/jobs/create_invoice/${this.id}`, {
                    job_id: this.id,
                    location: this.latlngLocation,
                    })
                    .then(res => {
                        console.log(res.data.status, res.data.message)

                        if (res.data) {
                            if (("status" in res.data) && !res.data.status) {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: res.data.message,
                                        icon: 'CheckIcon',
                                        variant: 'danger',
                                    },
                                });
                            } else {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Submitted successfully',
                                        icon: 'CheckIcon',
                                        variant: 'success',
                                    },
                                });
                            }
                        }
                        this.fetchData();
                    })
                    .catch(err => {
                        this.$toast({
                            component: ToastificationContent,
                            props: 'Oops! Somthing went wrong',
                            icon: 'AllertCircleIcon',
                            variant: 'success',
                        });
                        this.status = 'approved'
                        console.error(err)
                    });
            }
        },
        refStatus(statCode) {
            switch (statCode) {
                case 'fsddraft':
                return 'pending_review';
                case 'fsdpending_review':
                return 'approved';
                case 'virdraft':
                return 'vir_submitted';
                default:
                return '';
            }
        },
        buttonVal(val) {
            console.log(val);
            switch (val) {
                case 'fsddraft':
                return 'Submit for Review';
                case 'fsdpending_review':
                return 'Approve';
                case 'fsdapproved':
                return 'Send to Infusion';
                case 'virdraft':
                return 'Submit for Reporting';
                default:
                return '';
            }
        },
        changeTabStatus(tabstatus) {
            this.disableTabs = tabstatus
            this.editStatus = tabstatus
        },
        async getAddress(lat, long) {
            try {
                const { data } = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyBXcpLBxfEnWONKrLQBNsA13DWLlQUMl_E`);
                if (data.results[0].formatted_address.trim().length > 0) {
                    this.latlngLocation = data.results[0].formatted_address;
                }
            } catch (error) {
                console.log(error.message);
            }
        },
        tabEdit(val) {
            console.log('emmit working fine vehicle edit')
            this.editStatus = val
            this.disableTabs = val
            if (this.editStatus) {
                // this.triggerEdit()
                this.editStatus = false
            }
        },
        // triggerEdit() {
        //     this.$refs.edit_modal.show();
        // },
        triggerPendingTasks() {
            this.$refs.pending_tasks_modal.show();
        },
        getScrollTop(e) {
            window.scrollTo(0, 0)
        },
        allTypePermissionTest() {
            // let testPermission = true
            // testPermission = this.status !== 'vir_submitted' && this.status !== 'complete' && this.roleIdForBlock
            // if (this.profileId === 4) {
            //     testPermission = this.status.search('draft') > -1 && testPermission
            // }
            // console.log('test permission:1 ', this.status, this.$store.state.auth.user.roles[0], testPermission)
            // return testPermission
            return this.$store.state.auth.user.permissions.indexOf(`jobs-${this.statusCheck}`) > -1
        },
        confirmTask(id) {
            console.log('open confirm modal: ', id)
            this.taskId = id
            this.$refs.confirm_pending_task_modal.show();
        },
        triggerNoConfirmTask() {
            this.taskId = 0
            this.$refs.confirm_pending_task_modal.hide()
        },
        changeTaskStatus() {
            if (this.taskId) {
                this.$axios.post('jobs/changeTaskStatus', {
                    task_id: this.taskId,
                })
                .then(res => {
                    if (res.data.status) {
                        this.fetchData()
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: res.data.message,
                                icon: 'AllertCircleIcon',
                                variant: 'success',
                            },
                        });
                        this.$refs.confirm_pending_task_modal.hide()
                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            props: 'Oops! Somthing went wrong',
                            icon: 'AllertCircleIcon',
                            variant: 'success',
                        });
                    }
                })
                .catch(err => {
                    console.log(err)
                })
            }
        },
    },
}
</script>

<style>
.nav-pills .nav-link {
    color: #04297f !important;
    border-color: #04297f !important;
    margin-left: 5px;
    border-radius: 18px 18px 0 0;
}

.nav-pills .nav-link.active {
    color: #fff !important;
}
.upcase {
    text-transform: uppercase;
}
.job-proper-display .content.app-content {
    padding-top: 2rem !important;
}
.navbar-floating .header-navbar-shadow {
    background: none !important;
}
.float{
	position:fixed;
	bottom:30px;
	right:2.5%;
    background: #27a746 !important;
    color: #ffffff !important;
    border-color: transparent !important;
}
.feather-icon-btn {
    border: 1px solid #fff !important;
    background-color: #04297f !important;
}
a.link-color {
    color: #03216B !important;
    text-decoration: underline;
}
.task-modal {
    border-bottom: 1px solid #03216B;
}
.all-tasks-modal {
    overflow-y: scroll;
    max-height: 300px;
}
</style>
